
.text-border{
    border: 1px solid black;
    
   
}
.work-image{
    filter: hue-rotate(190deg);
}

ul{
    list-style-type: none;
}

a {
    color: #f1ce64;
}

/* .share {
    position: absolute;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -40px;
    border-radius: 80px;
} */

/* / */

.share nav {
    font-size: 0;
}

/* .share a {
    line-height: 80px;
    width: 80px;
    text-align: center;
    display: inline-block;
   
    overflow: hidden;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    margin: 0 -20px;
    box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.1);
} */

.share a:nth-child(1) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    margin-left: 0;
}

.share a:nth-child(1):hover {
    background-color: #61c5ec;
}

.share a:nth-child(2):hover {
    background-color: #3B5998;
}

.share a:nth-child(3):hover {
    background-color: #433bb5;
}

/* .share a:nth-child(4) {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    margin-right: 0;
}

.share a:nth-child(4):hover {
    background-color: #000000;
} */

.share:hover span,
.share.hover span {
    opacity: 0;
}

.share:hover a,
.share.hover a {
    border-radius: 50%;
    margin: 0 10px;
    color: #f1ce64;
    font-size: 28px;
}

.share:hover a:hover,
.share.hover a:hover {
    color: #fff;
}