.tcb-product-slider {
  background: #333;
  background-image: url(https://unsplash.it/1240/530?image=721);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}
.tcb-product-slider .carousel-control {
  width: 5%;
}
.tcb-product-item a {
  color: #147196;
}
.tcb-product-item a:hover {
  text-decoration: none;
}
.tcb-product-item .tcb-hline {
  margin: 10px 0;
  height: 1px;
  background: #ccc;
}
@media all and (max-width: 768px) {
  .tcb-product-item {
    margin-bottom: 30px;
  }
}
.tcb-product-photo {
  text-align: center;
  height: 180px;
  background: #fff;
}
.tcb-product-photo img {
  height: 100%;
  display: inline-block;
}
.tcb-product-info {
  background: #f0f0f0;
  padding: 15px;
}
.tcb-product-title h4 {
  margin-top: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tcb-product-rating {
  color: #acacac;
}
.tcb-product-rating .active {
  color: #ffb500;
}
.tcb-product-price {
  color: firebrick;
  font-size: 18px;
}

.details {
  margin: 50px 0;
}
.details h1 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 3px;
}
.details .back-link {
  text-align: center;
}
.details .back-link a {
  display: inline-block;
  margin: 20px 0;
  padding: 15px 30px;
  background: #333;
  color: #fff;
  border-radius: 24px;
}
.details .back-link a svg {
  margin-right: 10px;
  vertical-align: text-top;
  display: inline-block;
}
/* for second team section */
.main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D5D3E8;
  flex-wrap: wrap;
  padding-left: 10rem;
  padding-right: 10rem;
}
.profile-card {
  position: relative;
  font-family: sans-serif;
  width: 220px;
  height: 220px;
  background: #0697C9;
  padding: 30px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  margin: 0 25px;
}
.profile-card:hover {
  border-radius: 10px;
  height: 260px;
}
.profile-card .img {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.6s;
  z-index: 99;
}
.profile-card:hover .img {
  transform: translateY(-60px);
}
.img img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
}
.profile-card:hover img {
  border-radius: 10px;
}
.caption {
  text-align: center;
  transform: translateY(-80px);
  opacity: 0;
  transition: 0.6s;
}
.profile-card:hover .caption {
  opacity: 1;
}
.caption h3 {
  font-size: 21px;
  font-family: sans-serif;
}
.caption p {
  font-size: 15px;
  color: #0c52a1;
  font-family: sans-serif;
  margin: 2px 0 9px 0;
}
.caption .social-links a {
  color: #333;
  margin-right: 15px;
  font-size: 21px;
  transition: 0.6s;
}
.social-links a:hover {
  color: #0c52a1;
}
.team-context-about{
/* border:5px solid #0c52a1!important; */
height: 12rem;
overflow-y: hidden;


}
.team-context-about:hover{
  background-color: #0697C9;
  color: #fff;
  box-shadow: 0 0 22px #3336;
  height:28rem;
  transition: 1.5s;
  /* transform: translateY(-60px); */
}