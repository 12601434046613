.service-detail-padding{
    padding: 2rem;
}
.div-border{
    border-bottom: 1px solid black;
}
.margin-right{
    margin-right: 2rem;
}
.margin-left{
    margin-left: 3rem;
}