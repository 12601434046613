.clip-image{
    background-image: url("../../public/photo.avif");
    clip-path: ellipse(25% 40% at 50% 50%);
    width: 36rem;
    height:12rem;
    background-position: center;
    transform: rotate(-10deg);
}
.oval {
    width: 23rem;
    height:13rem;
  background-image: url("../../public/photo.avif");
  background-position: center;
    border-radius: 50%;
    transform: rotate(-15deg);
  }
  .margin-cocreate-image{
    margin-top: 0rem;
  }
  .Ekakshar-image{
    height:23rem;
    width:34rem;
  }
  @media (min-width:320px) and (max-width:480px){
    .margin-cocreate-image{
      margin-top: 2rem;
      padding: 0;
    }
    .Ekakshar-image{
      height:auto;
      width:34rem;
    }
  }