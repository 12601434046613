/* .text-size{
    font-size: 5rem;
} */
.small-div-padding{
    /* padding-left: 0.5rem; */
    padding-right: 1rem;
}
.padding-container{
    padding-left: 15rem;
    padding-right: 15rem;
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    /* gap: 10px; */
    /* background-color: #2196F3; */
    padding: 10px;
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 20px 0;
    font-size: 15px;
  }
  
  /* .item1 {
    grid-column: 1 / 9;
  } */

  /* .float-div{
    z-index: auto;
  } */

  .grid-box{
    display: grid;
    grid-template-columns: repeat(8, minmax(100px, 1fr)); grid-auto-rows: 80px;
    /* grid-template-columns: repeat(8, 1fr); grid-template-rows: 100px 100px; */
  }
  .item-span{
    width: 5px;
      transition: width 2s;
      height: 3.3rem;
      background-color: #B8D7FB;
      animation: width 2s timing-function;
     
  }

 

  
  .item-float{
    z-index:-1;
  }
  .item-span:hover{
    width:11.5rem;
  }
  .item-span-second{
    height: 3.3rem;
    margin-left: 2rem;
    width:10rem;
    background-color: #FFBDBA;
  }
  .item-span-third{
    height: 3.2rem;
    width: 14rem;
    background-color: #FEBDBA;
    
  }
  .item-span-fouth{
    height: 3.2rem;
    width: 12.2rem;
    background-color: #E2F4DE;
  }
  .item-span-fifth{
    height: 3.2rem;
    width: 18.6rem;
    background-color: #E2F4DE;
  }
  .item-span-six{
    height: 3.2rem;
    width: 17rem;
    background-color: #BFC2FA;
  }
  .item-span-seven{
    height: 3.2rem;
    width: 24.5rem;
    background-color: #F3ECDE;
  }
  .inner-line{
    background-color: #1D72F5;
  }
  .inner-line2{
    background-color: #DA4529;
  }
  .inner-line3{
    background-color: #51C76B;
  }
  .inner-line4{
background-color: #812DF0;
  }
  .inner-line5{
    background-color: #988C6E;
  }
  .small-box{
    background-color: #0697C9;
  }
  
  
  .motion-demo {
    /* min-height: 100vh; */
    position: relative;
    /* width: 100vw; */
    display: flex;
   
    justify-content: center;
    align-items: center;
   
   position: relative;
  }
  
  /* h1 {
    font-size: 50px;
    color: var(--primary-color);
    transform: translateY(-600px);
    animation: 1.2s slideIn ease-in-out forwards 1s;
    z-index: 10;
    opacity: 0;
    position: relative;
    transition: all;
  }
  
  h1::before {
      content: '';
      width: 0%;
      height: 76px;
      background-color: var(--secondary-color);
      position: absolute;
      bottom: -10px;
      animation: 1s underline ease-in-out forwards 2s;
      mix-blend-mode: screen;
  } */
  
  
  
  @keyframes skewBg {
    0% {
      transform: scale(.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes underline {
    100% {
      width: 100%;
    }
  }
  
  @keyframes slideIn {
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  

  


  .timeline-head{
    color: black; 
   text-align: center;
    font-size: 20px;
    margin: 10px 0 0 10px;
    white-space: nowrap;
    overflow: hidden;
    width: 41em;
    animation: type 2s steps(60, end); 
    animation-iteration-count: infinite;
    /* animation-delay: 2s; */
  }
  
  @keyframes type{ 
    from { width: 0; } 
  }