.wrapper {
    
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color:#eee; */
}

.animated-check {
    height: 10rem;
    width: 100rem
}

.animated-check path {
    fill: none;
    stroke: #7ac142;
    stroke-width: 4;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 1s linear forwards;
    stroke-linecap: round;
    stroke-linejoin: round
}

@keyframes draw {
    to {
        stroke-dashoffset: 0
    }
}
.padding-form-content{
    padding: 3rem;
}
.pddd{
    padding: 3rem;
}
@media (min-width: 320px) and (max-width: 480px) {
    .animated-check {
        height: 3rem;
        width: 9rem
    }
    .padding-form-content{
        padding: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 4rem;
    }
    .pddd{
        padding: 1.5rem;
    }
}