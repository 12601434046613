.contact-form{
    background-color: black;
    margin-top: 10%;
    margin-bottom: 5%;
    width: 70%;
}
.contact-form .form-control{
    border-radius:1rem;
}
.contact-image{
    text-align: center;
}
.contact-image img{
    border-radius: 6rem;
    width: 11%;
    margin-top: -3%;
    transform: rotate(29deg);
}
.contact-form form{
    padding: 14%;
}
.contact-form form .row{
    margin-bottom: -7%;
}
.contact-form h3{
    margin-bottom: 8%;
    margin-top: -10%;
    text-align: center;
    color: #0062cc;
}
.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    background: #dc3545;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}
.btnContactSubmit
{
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    color: #fff;
    background-color: #0062cc;
    border: none;
    cursor: pointer;
}
.form-padding{
    padding: 4rem;
}
.submit-button{
    margin-top: 3rem!important;
}

.error-font-size{
    font-size:11px;
}
.form-shadow{
    box-shadow: 0rem 0rem;
}
.bg-form-color{
    background-color: #fbe1bc;
}
.form-background{
    background-color: #AEDBED;
}
.padding-vertically{
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.form-width{
    width:75%;
}
@media (min-width:320px) and (max-width:480px){
    .form-width{
        width:90%;
    }
    .form-padding{
        padding: 2rem 1rem 2rem 1rem;

    }
    .bg-form-color{
        background-color:transparent;
    }
    .react-tel-input .form-control {
        width:auto !important;

    }
    .form-shadow{
        box-shadow:none;
    }
}