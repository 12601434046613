
.box-timeline1 {
  margin-left: 1.5rem;
}
/* .step-2 {
  position: absolute;
  top: 0;
  left: 100;
} */
.position-set{
    left:20
}
.bgbuttoncolor{
  background-color: #534f7e;
  color:#fff;
  z-index: 200;
}
.bgbuttoncolor:hover{
  background-color:#534f7e ;
  color:#fff;
}
.margin-contact {
  margin-left: 4rem;
}
.margin-align{
  margin-left: 2rem;
}
.start-100{
  left: 166%!important;
  
}
a{
  text-decoration: none!important;
  color: #fff!important;
}
.box-1{
  height: 11.5rem;
  width:50rem
}

.hows-text-para{
  font-family: "Calluna-Regular";
    font-size: 14px;
    text-align: justify;
}

@media (min-width:320px) and (max-width:480px){
  .box-1{
    height: 7rem;
    width:20rem
  }
  h5{
    font-size: 10px;
  }

  .hows-text-para{
    font-family: "Calluna-Regular";
      font-size: 8px;
      text-align:left;
  }
}