.stylebutton{
    background-color: rgb(0, 255, 102);
}
.style{
  background-color: rgb(167, 28, 28);
}
a{
    text-decoration: none;
    color: rgb(240, 250, 255);
}
.para-text{
    text-shadow: 2px,1px,2px,1px,black;
    font-size: 1.3rem;
    font-family: "Calluna-Regular"!important;
    text-shadow: 1.5px 1px black;
}
.button-carousel{
    transform: scale(1);
    margin-top: 1.5rem;
}
.button-carousel:hover{
    transform: scale(1.1);
    color: rgb(17, 18, 18);
}
.text-allignment{
    text-align: justify;
    width: 68rem;
    position: absolute;
    top:17rem;
    left: 15rem;
}
.heading-top{
    font-size:2rem ;
    font-weight: 800;
}
.image-shadow{
    filter:brightness(0.4);
   
}
.carousel-inner{
 height: 680px;
}
.carousal-show{
    display: block;
}
.padding-box{
    padding: 1.5rem;
}

.carousel-control-prev-icon {
    background-image: none;

}   

.carousel-control-next-icon{
    background-image: none;

}   
.margin-carousel-top{
    margin-top:0rem;
}

.margin-carousel{
    margin-top: 2rem;
}

.margin-button{
    margin-top: 2rem;
   
}

.car-button{
    display: flex;
    justify-content: start;
    align-items: center;
}

 /* for mobile view */

@media (min-width:320px) and (max-width:480px){
    .car-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousel-inner{
        height:27rem;
       }
    .text-allignment{
        text-align:justify;
        width:auto;
        /* width: 30rem; */
        position: absolute;
        top:7rem;
        left: 4rem;
    }
    .heading-top{
        font-size:1rem ;
        font-weight: 800;
    }
    .para-text{
        text-shadow: 2px,1px,2px,1px,black;
        font-size:0.7rem;
        font-family: "Calluna-Regular"!important;
        text-shadow: 1.5px 1px black;
    }
    .button-carousel{
        transform: scale(1);
        margin-top: 1rem;
    }
    .image-shadow{
    height:27rem;
       
    }
    .carousel-control-next-icon{
        width: 0.3rem;
    }
    .carousel-control-prev-icon {
        width: 0.3rem;
    }
    .carousel-indicators [data-bs-target]{
        width:20px;
       height: 2px;
    }


    .padding-box{
        padding: 0.5rem;
    
    
    }

    .btn{
        text-transform:capitalize;
    }
    .margin-carousel{
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }
    .margin-button{
        margin-top: 2rem;
        
    }

}
 /* for tablet view */
@media (min-width:481px) and (max-width:768px){
    .carousel-inner{
        height:21rem;
       }
       .image-shadow{
        height:21rem;
           
        }

        .carousel-control-next-icon{
            width: 1rem;
        }
        .carousel-control-prev-icon {
            width: 1rem;
        }

        .text-allignment{
            text-align: justify;
            width:auto;
            position: absolute;
            top:5rem;
            left:6rem;
        }
        .para-text{
            text-shadow: 2px,1px,2px,1px,black;
            font-size:0.7rem;
            font-family: "Calluna-Regular"!important;
            text-shadow: 1.5px 1px black;
        }
        .heading-top{
            font-size:0.8rem ;
            font-weight: 800;
        }
        .button-carousel{
            transform: scale(1);
            margin-top: 0.8rem;
        }
        .bttn{
            height: 2rem;
           width: 10rem;
        }
        .carousal-show{
            display: block;
        }
        .carousel-indicators{
            width: 11px;
              height: 2px;
        }
        .padding-box{
            padding: 0rem;
        }
}
@media (min-width:769px) and (max-width:1090px){
    .carousel-inner{
        height:37rem;
       }
       .image-shadow{
        height:37rem;
           
        }
        .text-allignment{
            text-align: justify;
            width:auto;
            position: absolute;
            top:10rem;
            left:10rem;
        }
        .para-text{
            text-shadow: 2px,1px,2px,1px,black;
            font-size:1rem;
            font-family: "Calluna-Regular"!important;
            text-shadow: 1.5px 1px black;

        }
        .heading-top{
            font-size:1.5rem ;
            font-weight: 800;
        }

       
}

@media (min-width:1091px) and (max-width:1365px){
    .carousel-inner{
        height:38rem;
       }
       /* .image-shadow{
        height:38rem;
           
        } */
        .text-allignment{
            text-align: justify;
            width:auto;
            position: absolute;
            top:10rem;
            left:10rem;
        }
        .para-text{
            text-shadow: 2px,1px,2px,1px,black;
            font-size:1.1rem;
            font-family: "Calluna-Regular"!important;
            text-shadow: 1.5px 1px black;

        }
        .heading-top{
            font-size:1.6rem ;
            font-weight: 800;
        }

}
@media only screen and (min-width: 1366px) and (max-width:1480px) {

    .para-text{
        text-shadow: 2px,1px,2px,1px,black;
        font-size:1.2rem;
        font-family: "Calluna-Regular"!important;
        text-shadow: 1.5px 1px black;
        

    }
    .heading-top{
        font-size:1.6rem ;
        font-weight: 800;
    }
    .text-allignment{
        text-align: justify;
        width:auto;
        position: absolute;
        top:16rem;
        left:12rem;
    }

    .carousel-inner{
        height: 680px;
       }
       .image-shadow{
        height:680px;
           
        }
}

